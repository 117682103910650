import styled, { css } from "styled-components"

import { DeviceMonitoringStatus } from "src/components/Devices/DeviceMonitoringStatus"
import { OfflineBanner } from "src/components/Homes/DeviceDetails/DeviceLastUpdated"
import {
  getDeviceHardwareType,
  getSensorValue,
  isEurekaDevice,
  isWaterLeakDetectionDevice,
  mouldRiskLevelStringify,
} from "src/data/devices/logic/deviceLogic"
import { HardwareType, TDevice } from "src/data/devices/types/deviceTypes"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { mColors } from "src/ui/colors"
import A1Sensor from "src/ui/icons/eureka-illustration.svg"
import HumidityIcon from "src/ui/icons/humidity.svg"
import MouldRiskIcon from "src/ui/icons/mold.svg"
import SoundIcon from "src/ui/icons/noise-monitoring.svg"
import P2Sensor from "src/ui/icons/p2-top.svg"
import P3Sensor from "src/ui/icons/p3-top.svg"
import TemperatureIcon from "src/ui/icons/temperature.svg"
import WLD from "src/ui/illustrations/wld-top.svg"
import { localizedDate } from "src/ui/LocalizedDate/localized-date"
import LocalizedTemperature from "src/ui/LocalizedTemperature"
import { MCardInteractive } from "src/ui/MCard/MCardInteractive"
import { BodyMixin, MText, SubtitleMixin } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { extractHHMM } from "src/utils/l10n"

export function Devices({
  devices,
  timezone,
}: {
  devices: TDevice[]
  timezone: string
}) {
  return (
    <>
      {devices.map((device) => {
        return (
          <Device key={device.device_id} data={device} timezone={timezone} />
        )
      })}
    </>
  )
}

export function Device({
  data: device,
  timezone,
}: {
  data: TDevice
  timezone: string
}) {
  const { t } = useTranslate()
  const { sound, temperature, humidity } = device.latest_sensor_values ?? {}
  const chargeStatus = device.charge_status
  const linkTo = Routes.SensorsWithDeviceId.location(device.device_id).pathname
  const isA1Device = isEurekaDevice(device)
  const isWLDDevice = isWaterLeakDetectionDevice(device)

  const sensorValueIconParams = {
    width: 25,
    color: mColors.textPrimary,
  }

  return (
    <MCardInteractive linkTo={linkTo} key={linkTo}>
      <Top $singleRow={isWLDDevice}>
        <DeviceName>
          <DeviceImg device={device} />

          <div>
            <DeviceTitle>{device.description}</DeviceTitle>
            <MText variant="bodyS" color="secondary">
              <DeviceMonitoringStatus device={device} />
            </MText>
          </div>
        </DeviceName>
      </Top>

      <DeviceInfo $hidden={isWLDDevice}>
        {!isA1Device && (
          <SensorValue>
            <SoundIcon {...sensorValueIconParams} />
            <SensorText>
              {device.offline ? `-` : `${getSensorValue(sound)} dB`}
            </SensorText>
          </SensorValue>
        )}
        <SensorValue>
          <TemperatureIcon {...sensorValueIconParams} />
          <SensorText>
            {chargeStatus === "connected_charging" ? (
              <MText variant="bodyS" color="secondary">
                {t(langKeys.sensor_data_charging_placeholder)}
              </MText>
            ) : device.offline ? (
              `-`
            ) : (
              <LocalizedTemperature value={temperature?.value} />
            )}
          </SensorText>
        </SensorValue>
        <SensorValue>
          <HumidityIcon {...sensorValueIconParams} />
          <SensorText>
            {chargeStatus === "connected_charging" ? (
              <MText variant="bodyS" color="secondary">
                {t(langKeys.sensor_data_charging_placeholder)}
              </MText>
            ) : device.offline ? (
              `-`
            ) : (
              `${getSensorValue(humidity)} %`
            )}
          </SensorText>
        </SensorValue>
        {isA1Device && (
          <SensorValue>
            <MouldRiskIcon {...sensorValueIconParams} />
            <SensorText>
              {mouldRiskLevelStringify(device.mould_risk_level, t)}
            </SensorText>
          </SensorValue>
        )}
      </DeviceInfo>

      <Bottom $hidden={isWLDDevice}>
        {device.offline ? (
          <OfflineBanner
            lastHeardFromAt={device?.last_heard_from_at}
            timezone={timezone}
          />
        ) : (
          <>
            <FormattedTime
              label={t(langKeys.device_last_update)}
              time={device?.last_heard_from_at}
              timezone={timezone}
              textAlign="start"
            />
            <FormattedTime
              label={t(langKeys.device_next_update)}
              time={device?.next_heartbeat_at}
              timezone={timezone}
              textAlign="end"
            />
          </>
        )}
      </Bottom>
    </MCardInteractive>
  )
}

function FormattedTime({
  label,
  time,
  timezone,
  textAlign,
}: {
  label: string
  time?: string
  timezone: string
  textAlign: "start" | "end"
}) {
  const clockType = useGetUser().clock_type

  if (!time) {
    return null
  }

  const statusText = extractHHMM(
    new Date(localizedDate(time, clockType, timezone)),
    clockType
  )
  return (
    <MText variant="bodyS" color="tertiary" textAlign={textAlign}>
      {label}: {statusText}
    </MText>
  )
}

const MonitoringDeviceTopCss = css`
  padding-bottom: ${spacing.M};
  margin-bottom: ${spacing.M};
  border-bottom: 1px solid ${mColors.divider};
`
const Top = styled.div<{ $singleRow?: boolean }>`
  ${({ $singleRow }) => ($singleRow ? "" : MonitoringDeviceTopCss)};
`

const DeviceTitle = styled.div`
  ${SubtitleMixin}
`

const DeviceInfo = styled.div<{ $hidden?: boolean }>`
  display: ${({ $hidden }) => ($hidden ? "none" : "flex")};
  gap: ${spacing.M};
  justify-content: space-evenly;
  margin-top: ${spacing.XS};
  font-size: 0.875rem;
`

const SensorValue = styled.div`
  ${BodyMixin}
  display: grid;
  place-items: center;
  text-align: center;
`

const Bottom = styled.div<{ $hidden?: boolean }>`
  display: ${({ $hidden }) => ($hidden ? "none" : "flex")};
  margin-top: ${spacing.M};
  padding-top: ${spacing.M};
  gap: ${spacing.L};
  justify-content: space-between;
  border-top: 1px solid ${mColors.divider};
`

const DeviceName = styled.div`
  display: flex;
  align-items: center;
`
const SensorText = styled.div`
  margin-top: ${spacing.XS};
  word-break: keep-all;
`

function DeviceImg({ device }: { device: TDevice }) {
  const deviceType = getDeviceHardwareType(device.hardware_version).type

  function DeviceImage() {
    switch (deviceType) {
      case HardwareType.A1:
        return <A1Sensor />
      case HardwareType.P3:
        return <P3Sensor />
      case HardwareType.WLD:
        return <WLD />
      default:
        return <P2Sensor />
    }
  }

  return (
    <DeviceImageBox>
      <DeviceImage />
    </DeviceImageBox>
  )
}

const DeviceImageBox = styled.div`
  margin-right: ${spacing.M};
  & > svg {
    width: 40px;
    height: 40px;
  }
`
